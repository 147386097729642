import * as io from 'io-ts';
var required = io.type({
  id: io.string,
  first_name: io.string
});
var optional = io.partial({
  email: io.string,
  primary_phone: io.string,
  last_name: io.string,
  preferred_name: io.string,
  pronoun: io.string
});
export var Contact = io.intersection([required, optional]);