import React from 'react'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'

import { LoginForm } from './components/auth/LoginForm'

import { contexts } from '@heimdall/pwa-core'

import { ApolloWrapper } from './components/base/Apollo'
import { GrommetWrapper } from './components/base/Grommet'
import { MaterialUIWrapper } from './components/base/MUI'
import { ProtectedRoute } from './components/ProtectedRoute'
import { Shell } from './components/shell/Shell'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons'
import { faThLarge, faSync, faUserCog } from '@fortawesome/free-solid-svg-icons'

library.add(
  faChartNetwork,
  faChevronLeft,
  faBars,
  faSignOut,
  faThLarge,
  faSync,
  faUserCog
)

const App: React.FC = () => {
  return (
    <Router>
      <ApolloWrapper>
        <contexts.FirebaseAuthProvider>
          <GrommetWrapper>
            <MaterialUIWrapper>
              <Switch>
                <Route path='/auth/login' component={LoginForm} />
                <ProtectedRoute exact path='/app/:app' component={Shell} />

                <Route
                  exact
                  path='/'
                  render={() => <Redirect to='/app/dashboard' />}
                />
                <Route
                  exact
                  path='/app'
                  render={() => <Redirect to='/app/dashboard' />}
                />
              </Switch>
            </MaterialUIWrapper>
          </GrommetWrapper>
        </contexts.FirebaseAuthProvider>
      </ApolloWrapper>
    </Router>
  )
}

export default App

// export const Logout: React.FC = () => {
//   const firebaseAuthContext = useContext(FirebaseAuthContext)

//   const signOut = (event: SyntheticEvent) => {
//     firebaseAuthContext.signOut()
//   }

//   return <Button label='Logout' onClick={signOut} />
// }
