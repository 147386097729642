import firebase from 'firebase/app';
import 'firebase/auth';
import { Left, Right } from 'fp-ts/lib/Either';
export var instantiate = function instantiate(appName) {
  var lookup = appName === undefined ? '[DEFAULT]' : appName;
  var app = firebase.apps.find(function (ele) {
    return ele.name === lookup;
  });

  if (app === undefined) {
    return new Left(Error('App name not provided and no DEFAULT app found'));
  } else {
    return new Right(app.auth());
  }
};
export var setLocalPersistence = function setLocalPersistence(firebaseAuth) {
  firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
};