import React, { useContext, SyntheticEvent } from 'react'

import { Box } from 'grommet'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { contexts } from '@heimdall/pwa-core'


type DrawerContentProps = {
  handler: (event: SyntheticEvent) => void
}

export const DrawerContent: React.FC<DrawerContentProps> = ({ handler }) => {
  const firebaseAuthContext = useContext(contexts.FirebaseAuthContext)

  const signOut = (_: SyntheticEvent) => {
    firebaseAuthContext.signOut()
  }

  return (
    <Box fill='vertical'>
      <Box background='light-3'>
        <List>
          <ListItem
            button
            onClick={handler}
            component={props => <Link {...props} to='/app/dashboard' />}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon='th-large' size='2x' fixedWidth />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
          <ListItem
            button
            onClick={handler}
            component={props => <Link {...props} to='/app/network' />}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon='chart-network' size='2x' fixedWidth />
            </ListItemIcon>
            <ListItemText primary='Network' />
          </ListItem>
        </List>
      </Box>
      <Box fill='vertical' direction='column-reverse' background='light-3'>
        <List>
        <ListItem button >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={['fas', 'user-cog']}
                size='2x'
                fixedWidth
              />
            </ListItemIcon>
            <ListItemText primary='User Settings' />
          </ListItem>
          <ListItem button onClick={signOut}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={['fas', 'sign-out']}
                size='2x'
                fixedWidth
              />
            </ListItemIcon>
            <ListItemText primary='Sign Out' />
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}
