import React, { useContext, SyntheticEvent, useState, useEffect } from 'react'

import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { Button } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Image, ResponsiveContext, Text } from 'grommet'
import { contexts } from '@heimdall/pwa-core'
import { Switch } from 'react-router-dom'
import { ProtectedRoute } from '../ProtectedRoute'

import notFound from '../../assets/img/undraw_not_found_60pq.svg'
import network_uc from '../../assets/img/undraw_good_team_m7uu.svg'

import { gql } from 'apollo-boost'

import { useApolloClient } from 'react-apollo-hooks'

import { DrawerContent } from './DrawerContent'

import { NetworkApp } from '@heimdall/pwa-network'

const drawerWidth = 300

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  list: {
    width: 300,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerToolbar: {
    backgroundColor: '#333333',
    color: '#fafafa',
  },
  content: {
    flexGrow: 1,
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '100vw - 72px',
    },

    // padding: theme.spacing.unit * 3,
  },
}))

export const Shell: React.FC = ({ match }: any) => {
  useEffect(() => setOpen(false), [])

  const classes = useStyles()
  // const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  function toggleOpen(event?: SyntheticEvent) {
    setOpen(!open)
  }

  function drawerToggleOpen(event?: SyntheticEvent) {
    if (open) setOpen(!open)
  }

  function renderAppName() {
    let appName = match.params.app
    return appName.charAt(0).toUpperCase() + appName.slice(1)
  }

  const GET_USERNAME = gql`
    query getUserData($uid: string) {
      getUserData(uid: $uid) @client {
        display_name
        active_org
      }
    }
  `

  const GET_ORGANIZATION = gql`
    query getOrganizationData($oid: string) {
      getOrganizationData(oid: $oid) @client {
        display_name
      }
    }
  `

  const [loading, isLoading] = useState(true)
  const [displayName, setDisplayName] = useState('Loading ...')
  const [organizationName, setOrganizationName] = useState('Loading ...')

  const client = useApolloClient()
  const ctx = useContext(contexts.FirebaseAuthContext)

  useEffect(() => {
    const fetchData = async () => {
      const userData = await client.query({
        query: GET_USERNAME,
        variables: { uid: ctx.uid },
      })

      const orgData = await client.query({
        query: GET_ORGANIZATION,
        variables: { oid: userData.data.getUserData.active_org },
      })

      setDisplayName(userData.data.getUserData.display_name)
      setOrganizationName(orgData.data.getOrganizationData.display_name)
      isLoading(false)
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading</div>
  } else {
    return (
      // <div>Done {data.getUserData.display_name}</div>
      <contexts.FirebaseAuthConsumer>
        {auth => (
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position='fixed'
              className={classNames(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar disableGutters={!open}>
                <Hidden smDown>
                  <IconButton
                    color='inherit'
                    aria-label='Open drawer'
                    onClick={toggleOpen}
                    className={classNames(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <FontAwesomeIcon icon={['far', 'bars']} />
                  </IconButton>
                </Hidden>

                <Hidden smUp>
                  <IconButton
                    color='inherit'
                    aria-label='Open drawer'
                    onClick={toggleOpen}
                    className={classNames(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <FontAwesomeIcon icon={['far', 'bars']} />
                  </IconButton>
                </Hidden>

                <Typography
                  variant='h6'
                  color='inherit'
                  noWrap
                  className={classes.grow}
                >
                  {renderAppName()}
                </Typography>
                <Hidden smDown>
                  <Box margin={{ right: 'small' }}>
                    <Button color='inherit'>{displayName}</Button>
                  </Box>
                </Hidden>
              </Toolbar>
            </AppBar>
            <Hidden smDown>
              <Drawer
                variant='permanent'
                className={classNames(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: classNames({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
                open={open}
              >
                <Toolbar className={classes.drawerToolbar}>
                  <Typography
                    variant='h6'
                    color='inherit'
                    className={classes.grow}
                  >
                    {organizationName}
                  </Typography>
                  <IconButton color='inherit' onClick={toggleOpen}>
                    <FontAwesomeIcon icon='chevron-left' />
                  </IconButton>
                </Toolbar>
                <DrawerContent handler={drawerToggleOpen} />
              </Drawer>
            </Hidden>

            <Hidden smUp>
              <SwipeableDrawer
                className={classNames(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: classNames({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
                open={open}
                onOpen={() => {
                  toggleOpen()
                }}
                onClose={() => {
                  toggleOpen()
                }}
              >
                <Toolbar className={classes.drawerToolbar}>
                  <Typography
                    variant='h6'
                    color='inherit'
                    className={classes.grow}
                  >
                    {organizationName}
                  </Typography>
                  <IconButton color='inherit' onClick={toggleOpen}>
                    <FontAwesomeIcon icon='chevron-left' />
                  </IconButton>
                </Toolbar>
                <DrawerContent handler={drawerToggleOpen} />
              </SwipeableDrawer>
            </Hidden>

            <main className={classes.content}>
              <div className={classes.toolbar} />

              <Box height='100vh - 64px' width='100%'>
                <Switch>
                  <contexts.FirebaseAuthProvider>
                    <ResponsiveContext.Consumer>
                      {size => (
                        <ProtectedRoute
                          exact
                          path='/app/network'
                          component={() => {
                            return <NetworkApp responsiveSize={size} />
                          }}
                        />
                      )}
                    </ResponsiveContext.Consumer>
                    <ProtectedRoute
                      exact
                      path='/app/dashboard'
                      component={NotFound}
                    />

                    {/* <ProtectedRoute exact path='/app/:app' component={NotFound} /> */}
                  </contexts.FirebaseAuthProvider>
                </Switch>
              </Box>
            </main>
          </div>
        )}
      </contexts.FirebaseAuthConsumer>
    )
  }
}

const NotFound: React.FC = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box fill animation='fadeIn'>
          <Box basis={size} margin='large' alignSelf='center'>
            <Image fit='contain' src={notFound} />
          </Box>
          <Box alignSelf='center' alignContent='center'>
            <Text textAlign='center'>
              You have found a new and mysterious place that even we did not
              know about!
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const NetworkUC: React.FC = ({ match }: any) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box fill animation='fadeIn'>
          <Box basis={size} margin='large' alignSelf='center'>
            <Image fit='contain' src={network_uc} />
          </Box>
          <Box alignSelf='center' alignContent='center'>
            <Text>Constructing Furiously</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

// const ExchangeRates = () => {
//   const query = gql`
//     {
//       rates(currency: 'USD') {
//         currency
//         rate
//       }
//     }
//   `

//   const { loading, data, error } = useQuery(query)

//   if (loading) return <p>Loading...</p>
//   if (error) return <p>Error :(</p>

//   return data.rates.map(({ currency, rate }: any) => (
//     <div key={currency}>
//       <p>
//         {currency}: {rate}
//       </p>
//     </div>
//   ))
// }
