import firebase from 'firebase/app';
import { Left, Right } from 'fp-ts/lib/Either';
export var instantiate = function instantiate(config, name) {
  try {
    var _lookup = name === undefined ? '[DEFAULT]' : name;

    var app = firebase.apps.find(function (ele) {
      return ele.name === _lookup;
    });

    if (app !== undefined) {
      return new Right(app);
    } else {
      return new Right(firebase.initializeApp(config, name));
    }
  } catch (err) {
    if (err.code === 'app/duplicate-app') {
      return lookup(name);
    } else {
      return new Left(Error(err));
    }
  }
};
export var lookup = function lookup(name) {
  var lookup = name === undefined ? '[DEFAULT]' : name;
  var app = firebase.apps.find(function (ele) {
    return ele.name === lookup;
  });

  if (app !== undefined) {
    return new Right(app);
  } else {
    return new Left(Error("Firebase App not found: ".concat(lookup)));
  }
};
export var disconnect = function disconnect(name) {
  var lookup = name === undefined ? '[DEFAULT]' : name;
  var app = firebase.apps.find(function (ele) {
    return ele.name === lookup;
  });

  if (app === undefined) {
    return new Left(Error('App name not provided and no DEFAULT app found'));
  } else {
    return new Right(app.delete());
  }
};