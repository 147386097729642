import * as React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faSave, faPlus, faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
library.add(faPen, faSave, faPlus, faTimes, faCircleNotch);
import { ContactList } from './ContactList';
import { Box, ResponsiveContext } from 'grommet';
export var NetworkApp = function NetworkApp(props) {
  return React.createElement(ResponsiveContext.Provider, {
    value: props.responsiveSize
  }, React.createElement(Box, null, React.createElement(ContactList, null)));
};