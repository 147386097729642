import * as React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faCircleNotch);
import { Box } from 'grommet';
export var Loading = function Loading() {
  return React.createElement(Box, {
    animation: 'fadeIn',
    align: 'center',
    justify: 'center',
    height: '80vh',
    alignContent: 'center',
    overflow: 'hidden'
  }, React.createElement(Box, null, React.createElement(FontAwesomeIcon, {
    icon: ['fal', 'circle-notch'],
    spin: true,
    size: '10x',
    color: '#006161'
  })));
};