import _classCallCheck from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

import { Field, ObjectType } from 'type-graphql';

var OrganizationSchema = function OrganizationSchema() {
  _classCallCheck(this, OrganizationSchema);
};

__decorate([Field(function (type) {
  return String;
}), __metadata("design:type", String)], OrganizationSchema.prototype, "id", void 0);

__decorate([Field(function (type) {
  return String;
}), __metadata("design:type", String)], OrganizationSchema.prototype, "display_name", void 0);

OrganizationSchema = __decorate([ObjectType({
  description: 'Object representing organization data'
})], OrganizationSchema);
export { OrganizationSchema };