import * as io from 'io-ts';
var required = io.type({
  uid: io.string,
  email: io.string
});
var optional = io.partial({
  first_name: io.string,
  last_name: io.string,
  display_name: io.string,
  active_org: io.string,
  organizations: io.array(io.string),
  admin: io.boolean
});
export var User = io.intersection([required, optional]);