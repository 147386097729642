import React from 'react'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

// TODO wire in global theme
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#006161',
    },
  },
  typography: {
    useNextVariants: true,
  },
  spacing: {
    unit: 8,
  },
})

export const MaterialUIWrapper: React.FC = ({ children }) => {
  return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
}
