import {merge} from 'lodash'

import React from 'react'

import ApolloClient from 'apollo-boost'

import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'

import { persistCache } from 'apollo-cache-persist'
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types'
import localForage from 'localforage'

import { ApolloProvider } from 'react-apollo-hooks'

import { resolvers as OrganizationResolvers } from '@heimdall/pwa-organization'
import { resolvers as UserResolvers } from '@heimdall/pwa-user'
import { FirebaseFactory, db } from '@heimdall/core-common-client'

// TODO move to core
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

FirebaseFactory.app.instantiate(config).fold(
  (err: Error) => {
    console.log(err)
    throw err
  },
  (app: FirebaseFactory.app.FirebaseApp) => {
    return app
  }
)

let firestore = db.Firestore.connect().fold(
  (err: Error) => {
    console.log(err)
    throw err
  },
  (app: db.Firestore.FirestoreConnection) => {
    return app
  }
)

const cache = new InMemoryCache()

persistCache({
  cache,
  storage: localForage as PersistentStorage<
    PersistedData<NormalizedCacheObject>
  >,
})

const apolloClient = new ApolloClient({
  uri: 'https://48p1r2roz4.sse.codesandbox.io',
  cache: cache,
  resolvers: {
    Query: merge(
      OrganizationResolvers.query.generate(firestore),
      UserResolvers.query.generate(firestore)
    ),
  },
})

export const ApolloWrapper: React.FC = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
