import React, { SyntheticEvent, useState, useContext } from 'react'

import {
  ResponsiveContext,
  Form,
  FormField,
  Box,
  Text,
  Image,
  TextInput,
  Button,
} from 'grommet'

import { contexts } from '@heimdall/pwa-core'

import background from '../../assets/img/subtle-grey.png'
import logo from '../../assets/img/poweredby_mirum-design_teal.svg'
import { Redirect } from 'react-router'

export const LoginForm: React.FC = () => {
  const firebaseAuthContext = useContext(contexts.FirebaseAuthContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const signIn = (event: SyntheticEvent) => {
    firebaseAuthContext.signInWithEmail(username, password)
  }

  if (firebaseAuthContext.isUserSignedIn() === true) {
    return <Redirect to="/app" />
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          background={{
            color: 'dark-1',
            dark: true,
            position: 'center',
            repeat: 'repeat',
            size: 'auto auto',
            image: 'url(' + background + ')',
          }}
          fill
          align="center"
          justify="center"
          gap="medium"
        >
          <Box align="center" animation="fadeIn">
            <Text size={size === 'large' ? '100px' : '56px'}>Heimdall</Text>

            <Box width="small">
              <Image
                fit="contain"
                margin="medium"
                src={logo}
                alignSelf="start"
              />
            </Box>
          </Box>
          <Box gap="medium" animation="fadeIn">
            <Form onSubmit={signIn}>
              <FormField name="username">
                <TextInput
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                  placeholder="Username"
                  size={size}
                />
              </FormField>
              <FormField name="password">
                <TextInput
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  placeholder="Password"
                  size={size}
                  type="password"
                />
              </FormField>
              <Box>
                <Button
                  margin={size}
                  type="submit"
                  primary
                  label="Sign In"
                  color="brand"
                />
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}
