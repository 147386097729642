import * as io from 'io-ts';
import { curry } from 'fp-ts/lib/function';
export function generateCodecFunction(logger, A, is) {
  return new io.Type(A.name, is, function (u, c) {
    var log = logger.extend('decode');
    log("Decoding ".concat(JSON.stringify(u)));

    if (is(u)) {
      var o = {};
      return io.success(Object.assign(o, io.exact(A).decode(u).value));
    } else {
      return io.failure(u, c);
    }
  }, function (a) {
    var log = logger.extend('encode');
    log("Encoding ".concat(JSON.stringify(a)));
    return Object.assign({}, io.exact(A).encode(a));
  });
}
export var generateCodec = curry(generateCodecFunction);