import React from 'react'

import { Redirect, Route } from 'react-router-dom'

import { contexts } from '@heimdall/pwa-core'

export const ProtectedRoute = ({ component: Component, ...rest }: any) => (
  <contexts.FirebaseAuthConsumer>
    {({ isUserSignedIn }) => (
      <Route
        {...rest}
        render={props =>
          isUserSignedIn() === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </contexts.FirebaseAuthConsumer>
)
