import React from 'react'

import { Grommet } from 'grommet'

// TODO wire in global theme
const grommetTheme = {
  global: {
    colors: {
      brand: '#006161',
      focus: '#006161',
      statusWarning: '#e28f20',
    },
    font: {
      family: 'Monteserrat',
      size: '14px',
      height: '20px',
    },
    focus: {
      border: {
        color: '#006161',
      },
    },
  },
  textInput: {
    disabled: {
      opacity: 1.0,
    },
  },
  layer: {
    container: {
      zIndex: 10000,
    },
    zIndex: 10000,
  },
}

export const GrommetWrapper: React.FC = ({ children }) => {
  return (
    <Grommet theme={grommetTheme} full>
      {children}
    </Grommet>
  )
}
